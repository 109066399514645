<template>
	<!-- flex_column -->
	<div class="aircraftBrand_page" style="height: 100%">
		<!-- 飞机库组件 -->
		<hangar :isPhone='isPhone' :title="$t('userinfo.name')">
		</hangar>
		<div id="back" v-show="isPhone" @click="back"
			style="z-index: 999; position: fixed;top: 50%;left: 0;width: 50px;height: 50px;border-radius: 50%;">
			<img src="../assets/image/back_phone.png" style="height: 100%;width: 100%;">
		</div>
		<div class="contactUS_page" style="padding: 20px">
			<div class="center">
				<div class="zui-flex-column info-box" :class="language == 'en' ? 'en_style2': 'zui-flex-column info-box'"
					style="font-size: 14px">
					<div class="flex_column" style="margin-bottom: 20px;margin-right:10px;">
						<el-upload class="avatar-uploader" :action="$apiUrl + '/sys/common/upload'"
							:show-file-list="false" :on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload">
							<img :src="avatar|userAvatar" class="zui-rounded-circle avatar" alt="" />
						</el-upload>
						
						<div class="justify zui-m-t-10 zui-text-black">{{ $t('userinfo.changeAvatar') }}</div>
					</div>
					<div class="userChange" style="margin-bottom: 10px">
						<div style="display: flex;align-items: center; margin-bottom: 10px">
							<div class="justify left-title zui-text-black"
								:class="language == 'en' ? 'en_style en_style1': 'justify left-title zui-text-black'">
								{{ $t('userinfo.realname') }}

							</div><span class="justify  en_style">:</span>
							<el-input class="flex_1" v-model="formData.userName" size="mini"></el-input>
						</div>

						<div style="display: flex;align-items: center; margin-bottom: 10px">
							<div class="justify left-title zui-text-black"
								:class="language == 'en' ? 'en_style en_style1': 'justify left-title zui-text-black'">
								{{ $t('userinfo.email') }}

							</div>
							<span class="justify  en_style" >:</span>
							<el-input class="flex_1" v-model="formData.email" size="mini"></el-input>
						</div>

						<div style="display: flex;align-items: center; margin-bottom: 10px">
							<div class="justify left-title zui-text-black"
								:class="language == 'en' ? 'en_style en_style1': 'justify left-title zui-text-black'">
								{{ $t('userinfo.account') }}

							</div>
							<span class="justify en_style">:</span>
							<el-input class="flex_1" v-model="formData.loginName" size="mini" :disabled="true">
							</el-input>
						</div>
						<div class="zui-flex-row zui-center-center zui-m-t-20"><span @click="editUser"
								class="userBtn zui-pointer">{{ $t('common.submit') }}</span></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import hangar from "../components/hangarCom";
	import signMd5Utils from '../assets/lib/signMd5Utils.js';
	import $config from "../api/config.js"
	import {
		mapState
	} from 'vuex';
	// 飞机品牌页面
	export default {
		data() {
			return {
				isPhone: false,
				avatar: '../assets/image/LOGO.png',
				formData: {
					userName: '',
					email: '',
					loginName: '',
					avatar: '',
					user_photo: '',
					userPhoto: ''
				},
			};
		},
		computed: {
			...mapState(['userInfo', 'token', 'isLogin', 'language']),
		},
		filters: {
			userAvatar: function(value) {
				// console.log(value);
				if ((value.search("http") != -1) && (value.search("https") != -1)) {
					return value
				} else {
					return $config.apiUrl + '/' + value
				}
			}
		},
		created: function() {
			if (/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
				this.isPhone = true
			} else {
				this.isPhone = false
			}
			if (this.isLogin == false) {
				this.$router.push({
					name: 'index'
				});
			}
			// console.log(this.userInfo);
			this.formData.userName = this.userInfo.userName;
			this.formData.email = this.userInfo.email;
			this.formData.loginName = this.userInfo.loginName;
			if (this.userInfo.userPhoto) {
				this.avatar = this.userInfo.userPhoto;
				this.formData.user_photo = this.userInfo.userPhoto;
				this.formData.userPhoto = this.userInfo.userPhoto;
			} else {
				this.avatar = require('../assets/image/11.png');
			}

		},
		methods: {
			inputEvn() {
				this.isInput = !this.isInput;
			},
			editUser() {
				//定义表单规则
				var rule = [{
						name: 'userName',
						checkType: 'notnull',
						checkRule: '',
						errorMsg: this.$t('common.formMsg.realname')
					},
					{
						name: 'email',
						checkType: 'email',
						checkRule: '',
						errorMsg: this.$t('common.formMsg.email')
					}
				];
				if (!this.$zuiChecker.check(this.formData, rule)) {
					this.$message({
						message: this.$zuiChecker.error,
						showClose: true,
						type: 'error',
						customClass: 'hahaha',
						duration: 2000,
						offset: 20
					});
					return;
				}
				let _this = this;
				let url = this.$apiUrl + '/front/editUserInfo';
				let sign = signMd5Utils.getSign(url, this.formData);
				let timestamp = signMd5Utils.getDateTimeToString();
				// console.log(this.formData);
				this.axios
					.put(url, this.formData, {
						headers: {
							'X-Access-Token': _this.token,
							'X-Sign': sign,
							'X-TIMESTAMP': timestamp
						}
					})
					.then(res => {
						// console.log(res);
						if (res.data.code == 200) {
							let userData = this.userInfo;
							userData.avatar = this.formData.avatar;
							userData.user_photo = this.formData.user_photo;
							userData.userPhoto = this.formData.user_photo;
							userData.userName = this.formData.userName;
							userData.phone = this.formData.phone;
							userData.email = this.formData.email;
							// console.log(userData);
							this.$store.dispatch('saveUserInfo', userData);
							this.$message({
								message: this.$t('userinfo.mesg'),
								showClose: true,
								type: 'success',
								customClass: 'hahaha',
								offset: 20,
								duration: 2000,
							});
							this.inputEvn();
						} else {
							this.$message({
								message: res.data.message,
								showClose: true,
								type: 'error',
								customClass: 'hahaha',
								duration: 2000,
								offset: 20
							});
							// console.log(res.message);
						}
					})
					.catch(err => {
						console.log(err);
						this.$message({
							message: this.$t('token'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20,
							onClose: () => {
								this.logOut();
								this.$router.push({
									name: 'login'
								});
							}
						});
					});
			},
			handleAvatarSuccess(res, file) {
				console.log(res, file);
				this.inputEvn();
				if (res.code == 0) {
					this.formData.avatar = res.message;
					this.formData.user_photo = res.message;
					this.formData.userPhoto = res.message;
				}
				this.avatar = res.message;
				// this.avatar = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt2M) {
					// this.$message.error('上传头像图片大小不能超过 2MB!');
					this.$message({
						message: this.$t('common.formMsg.mesg'),
						type: 'error',
						customClass: 'hahaha',
						duration: 2000,
						offset: 20
					})
				}
				return isLt2M;
			},
		},
		components: {
			hangar,
		},
	};
</script>

<style lang="scss" scoped>
	@import "../assets/scss/config.scss";
	@import "../assets/scss/mixin.scss";

	.left-title {
		width: 60px;
	}

	.en_style {
		text-align: right !important;
		margin-right: 8px;
		text-transform: none;
	}

	.en_style1 {
		width: 150px !important;
		text-align: right !important;
		text-align-last: right !important;
	}

	.en_style2 {
		width: 80% !important;
		text-align-last: auto !important;
	}

	.contactUS_page {
		width: 100%;
		height: 100%;
		background-image: url("../assets/image/14.jpg");
		background-position: top;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: rgba($color: #000000, $alpha: 1);
		display: flex;
		flex-direction: column;
		align-items: center;

		.center {
			min-height: 400px;
			margin: 20px 0;
			background-color: rgba($color: #ffffff, $alpha: 0.3);
			width: 50%;
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			padding: 30px;

			.info-box {

				width: 60%;

				.justify {
					font-size: 14px;
					text-transform: none;
					width: auto;
				}

				.avatar {
					width: 60px;
					height: 60px;
				}

				.userBtn {
					padding: 6px 12px;
					font-size: 12px;
					color: white;
					border-radius: 5px;
					background-color: #dea832;
				}
			}

		}


	}

	// 手机适配
	@media screen and (max-width: 1400px) {
		.contactUS_page {
			.center {
				width: 88%;
			}

			.info-box {
				width: 88% !important;
			}
		}


	}

	@media screen and (max-width: 960px) {
		.contactUS_page {
			.center {
				padding-top: 30px !important;
				padding: 0;
				margin: 0;
				width: 100%;

				.btnColor {
					padding: 4px 6px;
				}
			}

			.info-box {
				width: 98% !important;
			}

			.left-title {
				width: 70px !important;
			}

		}
	}
</style>
